// cSpell:disable
import { Button, message, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { LoginOutlined } from "@ant-design/icons";

import "./LogIn.css";
import { loginRequest } from "../../auth/authConfig";
import Footer from "../../components/footer/Footer";
// cSpell:enable

const LogIn = ({ instance, setIsError }) => {
  const handleLogIn = () => {
    instance.loginPopup(loginRequest).catch((err) => {
      console.log(err);
      message.error("Log in failed. Please refresh and try again.", 5);
      setIsError(true);
    });
  };

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Typography.Title level={1}>
            <FontAwesomeIcon icon={faPhoneVolume} />
            TE Supervisor
          </Typography.Title>
          <Button
            onClick={handleLogIn}
            className="sign-in-btn"
            icon={<LoginOutlined />}
          >
            Log in with WFE Microsoft account
          </Button>
          <Button
            type="link"
            href="https://blob.worldfamilyenglish.com/club-private-staging/data/trial-mode/online-experience/videos/Balloons.mp4"
          >
            Front door test 1
          </Button>
          <Button
            type="link"
            href="https://wfefd1.wftl.com.tw/club-private-staging/data/trial-mode/online-experience/videos/Balloons.mp4"
          >
            Front door test 2
          </Button>
        </div>
      </div>
      <Footer bgColor="#ececec" />
    </>
  );
};

export default LogIn;
